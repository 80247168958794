import { convertLocalToVenueTimezone } from "./utils";
import moment from "moment";

export const getIntegrationsColumns = (venue) => [
  {
    label: "ID",
    field: "id",
    id: "id",
    isHidden: true,
  },
  {
    label: "Status",
    id: "isActive",
    isHidden: false,
    field: (item) => {
      return item.isActive ? "Active" : "Disabled";
    },
  },
  {
    label: "Type",
    field: "type",
    id: "type",
    isHidden: false,
  },
  {
    label: "Name",
    id: "name",
    field: "name",
    isHidden: false,
  },
  {
    label: "Created By",
    id: "createdBy",
    field: (item) => {
      return item?.createdBy?.displayName;
    },
    isHidden: false,
  },
  {
    label: "Created At",
    id: "createdAt",

    field: (date) => {
      return date.createdAt?._seconds
        ? convertLocalToVenueTimezone(
            moment.utc(date.createdAt._seconds, "X"),
            null,
            venue,
          )
        : "-";
    },
    isHidden: false,
  },
];
