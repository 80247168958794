<template>
  <div class="integrations-page">
    <BackTitle class="mb-40" @click="goBack"> Integrations </BackTitle>
    <Guard permission="integrations.create" v-slot="{ isAvailable }">
      <div class="integrations-page__rightButton" v-if="isAvailable">
        <IconButton icon="plus" is-raised @click="goToCreation" class="mb-24">
          New integration
        </IconButton>
      </div>
    </Guard>
    <div class="integrations-page__content">
      <Loader v-if="isLoading" size="m" color="primary" class="mx-auto" />
      <div v-else-if="!integrationsList.length" class="empty">
        You haven't created integrations yet
      </div>
      <Table
        v-else
        name="integrationsTable"
        :columns="tableColumns"
        :rows="integrationsList"
        @on-view-click="handleOpenIntegrationDetail"
        has-settings
      />
    </div>
  </div>
</template>
<script>
import BackTitle from "@/components/common/BackTitle";
import Table from "@/components/table/Table";
import { getIntegrationsColumns } from "@/helpers/integrationsTable";
import { mapState, mapActions } from "vuex";
import Guard from "@/components/common/Guard";

export default {
  name: "IntegrationsListPage",
  components: { BackTitle, Table, Guard },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      integrationsList: (state) => state.integrations.list,
      venue: (state) => state.venues.selectedVenue,
    }),
    tableColumns() {
      return getIntegrationsColumns(this?.venue);
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchIntegrationsList();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchIntegrationsList: "integrations/fetchIntegrationsList",
    }),
    goBack() {
      this.$router.push({
        name: "Settings",
      });
    },
    goToCreation() {
      this.$router.push({
        name: "CreateIntegration",
      });
    },
    handleOpenIntegrationDetail(id) {
      this.$router.push({
        name: "EditIntegration",
        params: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.integrations-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__rightButton {
    width: fit-content;
    margin-left: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }
}
</style>
